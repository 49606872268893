<template>
  <div class="site-wrap">
    <template v-if="applicationReady">
      <site-header></site-header>
      <page-top>
        Buckeye Portal
      </page-top>
      <div style="min-height: 60vh">
        <router-view role="main" />
        <authentication-failure-popup-modal
          ref="authenticationFailureModal"
          @close="handleModalClose"
        />
      </div>
      <site-footer :plugin-version="appStore.getPluginVersion"></site-footer>
    </template>
    <div class="text-center my-5 h3" v-else>
      Loading application...
    </div>
  </div>
</template>
<script lang="js">
import SiteHeader from "@/src/components/shared/SiteHeader.vue";
import SiteFooter from "@/src/components/shared/SiteFooter.vue";
import {useAppCoreStore} from "@/src/stores/app-core";
import PageTop from "@/src/components/shared/PageTop.vue";
import AuthenticationFailurePopupModal from '@/src/components/AuthenticationFailurePopupModal.vue'

export default {
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  components: {
    SiteHeader,
    SiteFooter,
    PageTop,
    AuthenticationFailurePopupModal
  },
  data() {
    return {
      currentUser: null,
      loading: false,
      error: null,
      modalMessage: null
    }
  },
  computed: {
    applicationReady() {
      return !this.loading && this.appStore.currentUser;
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.appStore.loadApplicationData();
      await this.appStore.loadCurrentUser();
    } catch(error) {
      this.error = error;
    }
    this.loading = false;
  },
  methods: {
    handleModalClose() {
      window.location.reload();
    },
    displayModal() {
      $('#authenticationFailureModal').modal();
    }
  },
  created() {
    window.displayModal = this.displayModal;
  }
}

</script>
