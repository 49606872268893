<template class="page-content">
  <ModalLayout :ref="modalRef"
    modal-id="authenticationFailureModal"
    :loading="false"
    modal-style="background-color: #000"
    @close="modalClosed()">
    <template #modal-header>
      <h5>Logged Out</h5>
    </template>
    <template #modal-body>
      You have been logged out due to inactivity.

      <hr>
      <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="modalClosed">Close</button>
    </template>
  </ModalLayout>
</template>

<script>
import ModalLayout from "@/src/components/shared/ModalLayout.vue";

export default {
  components: {
    ModalLayout
  },
  props: {
    modalRef: { type: String, required: true },
  },
   data() {
    return {
      modalRef: 'authenticationFailureModal'
    }
  },
  methods: {
    modalClosed() {
      this.$emit('close');
    },
  },
};
</script>