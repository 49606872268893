<template class="page-content">
  <!-- Modal -->
  <div class="modal fade bd-example-modal-lg page-content"
       :style="modalStyle"
       :id="modalId"
       tabindex="-1"
       role="dialog"
       :aria-labelledby="`${modalId}Label`"
       aria-hidden="true"
       data-backdrop="static"
       data-keyboard="false">
    <div class="modal-dialog modal-wide modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header" :id="`${modalId}Label`">
          <slot name="modal-header"></slot>
          <button type="button" @click="close" class="close" data-dismiss="modal" aria-label="close">
            <font-awesome-icon :icon="['fas', 'fa-times']" aria-hidden="true" />
          </button>
        </div>
        <div class="modal-body">
          <loading-section v-if="loading"/>
          <slot name="modal-body" v-else></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">

import LoadingSection from "@/src/components/shared/LoadingSection.vue";

export default {
  components: {
    LoadingSection
  },
  props: {
    modalId: { required: true },
    loading: { default: false },
    modalStyle: { required: false, default: '', type: String }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>
