import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP, APIBaseRoute} from "@/src/endpoints";
import { handleErrors, makeRequest } from "@/src/utils";

export const useTemplateStore = defineStore('template', {
  state: ()=> {
    return {
      // lookup data
      categories: [],
      templates: [],
      variables: [],
      transplantCenters: [],
      organGroups: [],
      categoryTypes: [],
      categoryFields: []
    }
  },
  getters: {
    getVariables() {
      return this.variables;
    },
    getCategoryTypes() {
      return this.categoryTypes;
    },
    getTemplateList() {
      return this.templates.map((item) => {
        return {
          id: item.id,
          code: item.communication_template_category.category_name
        }
      })
    },
    translateOrganGroup: (state) => (id) => {
      const found = state.organGroups.find((row) => { return row.id === id; });
      return found ? found.name : null;
    },
    translateTxcCode: (state) => (id) => {
      const found = state.transplantCenters.find((row) => { return row.id === id; });
      return found ? found.code : null;
    },
    translateCategoryName: (state) => (id) =>  {
      const found = state.categories.find((row) => { return row.id === id; });
      return found ? found.category_name : null;
    },
    translateCategoryType: (state) => (id) =>  {
      const found = state.categoryTypes.find((item) => { return item.key === id; });
      return found ? found.value : null;
    },
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, { headers: {'Accept': 'application/json'}, params: params}).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    loadPaginatedEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, {
          headers: {'Accept': 'application/json'},
          params: params })
          .then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },

    // Load All Categories to Store
    loadCategories() {
      return this.loadEntity(APIRoute(EP.admin.templates_categories.index_all), 'categories');
    },
    loadPaginatedCategories(params) {
      return this.loadPaginatedEntity(APIRoute(EP.admin.templates_categories.index), 'categories',params)
    },
    loadCategoryTypes() {
      return this.loadEntity(APIRoute(EP.admin.templates_categories.category_types), 'categoryTypes')
    },
    loadCategoryTypesByOrganGroupAndTxc(organ_group_id, transplant_center_id) {
      const params = { 
        organ_group_id: organ_group_id, 
        transplant_center_id: transplant_center_id 
      };
      return this.loadEntity(APIRoute(EP.admin.templates_categories.category_types_by_organGroups_and_txc), 'categories', params)
    },
    // Load Category to Store
    loadCategory(id) {
      return new Promise((resolve, reject) => {
        beApiClient.get(APIRoute(EP.admin.templates_categories.show, { id: id })).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // Load Category Fields
    loadCategoryFields(categoryId) {
      return new Promise((resolve, reject) => {
        beApiClient.get(APIRoute(EP.admin.templates_categories.metadata_fields), { params: { category_id: categoryId }}).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // Save Category
    createCategory(payload) {
      return new Promise((resolve, reject) => {
        beApiClient.post(APIRoute(EP.admin.templates_categories.create), payload).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // Save Category
    updateCategory(payload) {
      return new Promise((resolve, reject) => {
        beApiClient.patch(APIRoute(EP.admin.templates_categories.update, { id: payload.id }), payload).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // load variable reference
    loadVariables() {
      return this.loadEntity(APIRoute(EP.admin.converter_variables.index), 'variables')
    },
    // load transplant centres
    loadTransplantCentres() {
      return this.loadEntity(APIRoute(EP.admin.transplant_centers.index), 'transplantCenters')
    },
    // load organ groups
    loadOrganGroups() {
      return this.loadEntity(APIRoute(EP.admin.organ_groups.index), 'organGroups')
    },
    // Load All Templates to Store
    loadTemplates(params) {
      return this.loadPaginatedEntity(APIRoute(EP.admin.templates.index,params), 'templates',params)
    },
    // Load Template to Store
    loadTemplate(id) {
      return new Promise((resolve, reject) => {
        beApiClient.get(APIRoute(EP.admin.templates.show, { id: id })).then(response => {
          if(response && response.data && response.data.hasOwnProperty('has_instance?')) response.data.has_instance = response.data['has_instance?'];
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // Save New Template
    createTemplate(payload) {
      return new Promise((resolve, reject) => {
        beApiClient.post(APIRoute(EP.admin.templates.create), payload).then(response => {
          if(response && response.data && response.data.hasOwnProperty('has_instance?')) response.data.has_instance = response.data['has_instance?'];
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // Update Template
    updateTemplate(payload) {
      return new Promise((resolve, reject) => {
        beApiClient.patch(APIRoute(EP.admin.templates.update, { id: payload.id }), payload).then(response => {
          if(response && response.data && response.data.hasOwnProperty('has_instance?')) response.data.has_instance = response.data['has_instance?'];
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // Check for duplicate template
    verifyTemplate(categoryId, organGroupId, tcId) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.templates.verify), { params: { category_id: categoryId, organ_group_id: organGroupId, tc_id: tcId } });
    },
    // Validate match for preview
    validateMatch(donorCode, matchCode, txcCode) {
      return new Promise((resolve, reject) => {
        beApiClient.get(APIRoute(EP.admin.templates.validate_match), { params: { donor_code: donorCode, match_code: matchCode, txc_code: txcCode }}).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // return template preview using selected match
    previewTemplate(templateId, matchId) {
      return new Promise((resolve, reject) => {
        beApiClient.get(APIRoute(EP.admin.templates.preview, { template_id: templateId }),{ params: { match_id: matchId }}).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // Generate New Template Instance Body (non-destructive)
    generateInstanceBody(communication_template_id, match_id) {
      return new Promise((resolve, reject) => {
        beApiClient.get(APIRoute(EP.admin.template_generation.generate_instance_body, { communication_template_id: communication_template_id }), { params: { match_id: match_id }}).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // Create Initial Template Instance from match (creates instance record)
    generateTemplateInstanceFromMatch(match_id, payload) {
      return new Promise((resolve, reject) => {
        beApiClient.post(APIRoute(EP.admin.template_generation.generate_template_instance_from_match, { match_id: match_id }), payload).then(response => {
          this.instance = response.data; // save working copy to store
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // Load Existing Template Instance
    loadTemplateInstance(match_id, communication_template_instances_id) {
      return new Promise((resolve, reject) => {
        beApiClient.get(APIRoute(EP.admin.template_generation.template_instance, { match_id: match_id, communication_template_instances_id: communication_template_instances_id })).then(response => {
          this.instance = response.data; // save working copy to store
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // Update Existing Template Instance
    updateTemplateInstance(match_id, communication_template_instances_id, payload) {
      return new Promise((resolve, reject) => {
        beApiClient.patch(APIRoute(EP.admin.template_generation.template_instance, { match_id: match_id, communication_template_instances_id: communication_template_instances_id }), payload).then(response => {
          this.instance = response.data; // save working copy to store
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // Reset Existing Template Instance
    resetTemplateInstance(match_id, communication_template_instances_id) {
      return new Promise((resolve, reject) => {
        beApiClient.post(APIRoute(EP.admin.template_generation.reset_template_instance, { match_id: match_id, communication_template_instances_id: communication_template_instances_id })).then(response => {
          this.instance = response.data; // save working copy to store
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    // load latest Template Instance (they are versioned, this will return the latest)
    latestTemplateInstance(match_id, communication_template_category_id) {
      return new Promise((resolve, reject) => {
        beApiClient.get(APIRoute(EP.admin.template_generation.latestInstance), { params: { match_id: match_id, communication_template_category_id: communication_template_category_id }}).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
  }
});
